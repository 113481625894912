.primary-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 12px 36px;
  border-radius: 10px;
  border: 1px solid #000000;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #000000;
  color: #ffffff;
  transition: all 0.3s;
  &:hover {
    background: #ffffff;
    color: #000000;
    transition: all 0.3s;
  }
  &:focus {
    outline: none;
  }
  &.reverse {
    color: #000;
    background-color: #fff;
    &:hover {
      background-color: #000;
      color: #fff;
      transition: all 0.3s;
    }
  }
}
strong {
  font-weight: 500;
}
