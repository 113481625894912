/* Importing Bootstrap SCSS file. */
@import "../node_modules/bootstrap/scss/bootstrap";
@import "ngx-toastr/toastr";
@import "@ctrl/ngx-emoji-mart/picker";
@import "./assets/styles/emoji-custom.scss";
@import "./assets/styles/admin.scss";
@import "./assets/styles/user.scss";
@import "./assets/styles/main.scss";
@import "./assets/styles/normalize.scss";

//admin
* {
  font-family: "termina", sans-serif;
  font-weight: normal;
  font-style: normal;
}
html,
body {
  height: 100%;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
body {
  display: flex;
  flex-direction: column;
}
h1 {
  font-weight: 500;
  font-size: 70px;
  line-height: 100%;
}
h2 {
  font-weight: 500;
  font-size: 36px;
  line-height: 150%;
}
h3 {
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
}
h4 {
  font-weight: 500;
  font-size: 28px;
  line-height: 150%;
}
h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
}
h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
}
.container {
  max-width: 1170px;
}
.main-container {
  max-width: 1170px;
  margin: 0 auto;
}
textarea:focus,
input:focus,
select:focus {
  outline: none;
  box-shadow: none;
}
.ng-submitted input.ng-invalid {
  border: 1px solid red !important;
}
.ng-submitted select.ng-invalid {
  border: 1px solid red !important;
}
.ng-submitted textarea.ng-invalid {
  border: 1px solid red !important;
}
.ng-submitted .check:not(:checked).ng-invalid + .checkmark {
  border: 1px solid red !important;
}
.ng-submitted ngx-editor.ng-invalid {
  .NgxEditor {
    border: 1px solid red !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.select-sort {
  background: #f9f9f9;
  border: none;
  height: 31px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 6.5px 3px 6.5px 4px;
  option {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
}
.input-regular {
  height: 36px;
  width: 100%;
  padding-left: 12px;
  font-size: 14px;
  line-height: 150%;
  border: none;
  background: #f9f9f9;
  &::placeholder {
    color: #000;
    font-size: 14px;
    line-height: 150%;
  }
}
.btn-admin {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 12px 36px;
  border-radius: 10px;
  border: 1px solid #000000;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: #000000;
  color: #ffffff;
  transition: all 0.3s;
  &:hover {
    transition: all 0.3s;
    background: #ffffff;
    color: #000000;
  }
  &:focus {
    outline: none;
  }
}
.btn-cancel {
  background: #ffffff;
  color: #000000;
  &:hover {
    background: #000000;
    color: #ffffff;
  }
}
.pagination {
  margin-bottom: 0;
  .page-link {
    color: #000;
    border: none;
    margin-left: 6px;
    margin-right: 6px;
    line-height: 1;
    &:hover {
      background: none;
      border: none;
    }
    &:focus {
      box-shadow: none;
      background: none;
    }
  }
  .page-item.disabled {
    &:first-child,
    &:last-child {
      display: none;
    }
  }
  .page-item.disabled .page-link {
    border: none;
    a {
      color: #000;
    }
  }
  .page-item.active .page-link {
    background-color: #fff;
    opacity: 1;
    font-weight: 500;
    border: none;
  }
  .page-item:last-child .page-link {
    border: none;
    font-size: 25px;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: -5px;
    margin-right: -12px;
  }
  .page-item:first-child .page-link {
    border: none;
    font-size: 25px;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: -5px;
  }
}
.circle-green {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: #00aa3c;
}
.modal-backdrop {
  --bs-backdrop-opacity: 0.6;
}
app-root {
  height: 100%;
}
app-layouts-main,
app-layout-user {
  display: flex;
  flex-direction: column;
  height: 100%;
  main {
    flex: 1 0 auto;
  }
  .footer {
    flex-shrink: 0;
  }
}
app-layout-user-chat,
app-layout-user-blacklist,
app-layout-personal-info,
app-list-matches,
app-questionnaire-steps,
app-questionnaire-successfully {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

app-layout-admin-account{
  a{
    color: #000000;
    &:hover{
      color: #000000;
    }
  }
}

.stories-row-item:nth-child(3n + 1) {
  .stories-info {
    background: #0073c3;
  }
}
.stories-row-item:nth-child(3n + 2) {
  .stories-info {
    background: #d2b9a5;
  }
}
.stories-row-item:nth-child(3n + 3) {
  .stories-info {
    background: #ff3223;
  }
}

.emoji-mart-anchor.emoji-mart-anchor-selected > div > svg > path {
  color: #00aa3c;
}
.emoji-mart-anchor > div > svg > path {
  color: #fff;
}
.emoji-mart-anchor > div > svg:hover {
  opacity: 0.6;
}
.scroll-disable {
  overflow: hidden;
}
