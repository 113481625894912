.input-regular-main{
  border: 1px solid #A3A3BD;
  border-radius: 10px;
  height: 41px;
  padding: 9px 20px;
  width: 100%;
  &::placeholder {
    color: #A3A3BD;
    font-size: 14px;
    line-height: 150%;
  }
}

.select-regular-main{
  border: 1px solid #A3A3BD;
  border-radius: 10px;
  height: 41px;
  padding: 9px 15px;
  width: 100%;
  &:required:invalid {
    color: #9B9B9B
  }
  option[value=""][disabled] {
    display: none;
  }
  option {
    color: black;
  }
}

.checkbox-regular-main {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 34px;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid #000000;
    border-radius: 3px;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
  input:checked ~ .checkmark {
  }
  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 4px;
    top: 0.5px;
    width: 6px;
    height: 10px;
    border: solid #000000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
