.emoji-mart {
  font-family: inherit;
  border: 0.5px solid #bfbfbf;
  border-radius: 0px;
}
.emoji-mart-bar {
  .emoji-mart-anchors {
    align-items: center;
    .emoji-mart-anchor {
      div > svg {
        cursor: pointer;
      }
    }
  }
}
.emoji-mart-scroll {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(191, 191, 191, 0.4);
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #00aa3c;
    border-radius: 7px;
    height: 30%;
  }
}
.emoji-mart-anchor-bar {
  background-color: #00aa3c !important;
}
